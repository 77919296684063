import React, {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from '@headlessui/react'
import axios from "axios";

function Info(props) {


    return (
        <div className="dark:text-white bg-white dark:bg-gray-900 text-gray-600 flex-1 w-full py-4 mx-auto md:px-8 px-5 border-black">
            <div className="max-w-4xl mx-auto pt-20">
                <div className="flex justify-between flex-col md:flex-row">
                    <h2 className="text-xl sm:text-4xl lg:text-4xl leading-none font-extrabold tracking-tight text-gray-900 dark:text-white">What
                        is Domainprinter?</h2>
                    <a className="pt-6 md:pt-0" href="https://www.producthunt.com/posts/domainprinter?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-domainprinter" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=314060&theme=light" alt="Domainprinter - A clean and simple domain generator | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
                </div>

                <br/>
                <div className="max-w-4xl sm:text-xl sm:leading-10 space-y-6 mb-6">
                    <p>
                        Domain names can be difficult to come up with. Wouldn't it be great if there was an easy way to
                        generate them? Well now there is! Our new tool makes it super easy to create beautiful domains in
                        seconds.
                    </p>
                    <p>Domainprinter is a simple domain generator that matches your keyword towards thousands of common
                        prefixes and suffixes. The tool also has a synonym finder which helps you find new keywords.</p>

                </div>
                <br/>
                <br/>
                <div className="flex justify-between flex-col md:flex-row">
                    <h2 className="text-xl sm:text-4xl lg:text-4xl leading-none font-extrabold tracking-tight text-gray-900 dark:text-white">What
                        is a domain?</h2>
                </div>

                <br/>
                <div className="max-w-4xl sm:text-xl sm:leading-10 space-y-6 mb-6">
                    <p>
                        A domain name is the address of a website or web page on the internet. It is what you type into your browser to get to that website or web page. It is used in the Internet's domain name system (DNS) to identify the webpages and other resources that make up the Internet.
                    </p>
                    <p>A domain name can be anything from yourname.com, to yourname.co, to yourname.org, and so on.
                        Domain names are usually made up of letters and numbers, but they can also include other characters like hyphens (-) or underscores (_).</p>
                    <p>
                        Domain names were first introduced in 1985 and were originally distributed to organizations by InterNIC, a subsidiary of NSF, for $100 per year. The first domain name was symbolics.com, which was registered to Symbolics Inc., a Massachusetts-based computer manufacturer.
                    </p>
                    <p>
                        There are many domain name registrars out there to choose from, but some of them are better than others. You want to find a domain registrar that offers everything you need. This includes an easy way to purchase the domain, a low price point, and a high level of customer service.
                    </p>
                </div>
                <br/>
                <br/>
                <div className="flex justify-between flex-col md:flex-row">
                    <h2 className="text-xl sm:text-4xl lg:text-4xl leading-none font-extrabold tracking-tight text-gray-900 dark:text-white">How does domain names affect SEO?</h2>
                </div>

                <br/>
                <div className="max-w-4xl sm:text-xl sm:leading-10 space-y-6 mb-6">
                    <p>
                        Domain names are important for the SEO of any website. It is a must to have a domain name that is short, easy to remember and relevant. One should also make sure that the domain name is not too similar to other domains in order to avoid any confusion.
                    </p>
                    <p>
                        There are many factors that affect the functionality of a domain name and how it performs on search engines. The length, the word count, the relevance of the domain name to its content and keywords, and more all play a part in how well a domain ranks on search engines.                    </p>
                </div>
                <br/>
                <br/>
                <h2 className="text-xl sm:text-4xl lg:text-4xl leading-none font-extrabold tracking-tight text-gray-900 dark:text-white">
                    Tips for buying a domain name
                </h2>
                <br/>
                <div className="max-w-4xl sm:text-xl sm:leading-10 space-y-6 mb-6">
                    <p>
                        1. Avoid the hyphen. It can be a sign of spam, and you don't want your business associated with
                        that! Plus users are prone to forgetting the hyphen when trying to reach your domain. They might end
                        up at a competitors website!
                    </p>
                    <p>
                        2. Targeting keywords related to the industry in which it falls will help increase organic rankings in search engines! Use our generator find a perfect keyword-rich domain name for you!
                    </p>
                    <p>
                        3. Avoid doubling your letters, and you'll be less likely to lose traffic due to typos.
                    </p>
                    <p>
                        4. Don’t make the mistake of choosing a website name that is hard to say or spell. One of the leading
                        reasons for lost traffic is typos, so keep this in mind when picking your domain name!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Info;
