import React, {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from '@headlessui/react'
import axios from "axios";

function InfoAvailability(props) {

    return (
        <div className="dark:text-white bg-white dark:bg-gray-900 text-gray-600 flex-1 w-full py-4 mx-auto md:px-8 px-5 border-black">
            <div className="max-w-4xl mx-auto pt-20">
                <div className="flex justify-between flex-col md:flex-row">
                    <a className="pt-6 md:pt-0" href="https://www.producthunt.com/posts/domainprinter?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-domainprinter" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=314060&theme=light" alt="Domainprinter - A clean and simple domain generator | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
                </div>
                <br/>
                <div className="max-w-4xl sm:text-xl sm:leading-10 space-y-6 mb-6">
                    <h2>What can I use a domain for?</h2>
                    <p>
                        The domain registration, which guarantees you the right to use a given domain worldwide, is only the first step for you or your company on the road to an online presence on the Internet, which you yourself define and decide over.
                        There are two very basic possibilities for use, by having your own domain:
                    </p>
                    <h2>You can get your own unique email addresses</h2>
                    <p>
                        Custom email addresses based on your domain name make your email communications independent of your phone and ISP, as well as advertising-funded services like Gmail and Yahoo.
                    </p>
                    <h2>You can view a website through the domain</h2>
                    <p>
                        A website via the domain means publishing exactly the content you want, and which in the long run can contribute to search engines, such as. Google, begins to index your pages, and present the content to their users.
                        Website and email via your domain you get the option of when choosing a web host.
                    </p>
                    <h2>You do not need to have a website on your domain</h2>
                    <p>
                        A website via the domain means publishing exactly the content you want, and which in the long run can contribute to search engines, such as. Google, begins to index your pages, and present the content to their users.
                        Website and email via your domain you get the option of when choosing a web host.
                    </p>
                    <h2>If you have a website, you should also have your e-mail via the domain</h2>
                    <p>It is a common thread between visitors to your website and the communication with them that it is written back and forth via e-mail addresses on the domain. It is unprofessional, especially in a business context, to sign with an email address that does not belong to the domain. It therefore helps to increase the credibility of your online presence that you choose to use email through your domain.</p>
                    <h2>Content on the domain in the form of a website</h2>
                    <p>Conversely, as a user of e-mail addresses on a domain, you can expect those you communicate with to enter your domain to look for a website. A website that can then help to back up your communication. The website of your domain can be anything from a simple front page with your contact information, to one with more pages and more complete information. It can be anything from a portfolio to a definite webshop.</p>
                    <h2>Your name is searched regardless of whether you have a website or not</h2>
                    <p>Get more control - If you choose to have content on your domain in the form of a website, you also make it possible to be found via search engines. It is therefore important, for e.g. a business, to create an online presence through a domain that existing customers can find, but also potential new ones.
                        You help to manage the content that is found, by being represented via a domain and associated website.</p>
                </div>
                <br/>
                <br/>
            </div>
        </div>
    );
}

export default InfoAvailability;
