import Layout from "../components/layout";
import SEO from "../components/seo";
import React, {Fragment, ReactElement, useState, useEffect} from "react"
import axios from 'axios';
import DomainBox from "../components/domainBox";
import Synonym from "../components/synonym";
import Info from "../components/info";
import ReactLoading from "react-loading";
import {Listbox, Switch, Transition} from '@headlessui/react'
import {SelectorIcon} from '@heroicons/react/solid'
import {createGlobalState} from "react-hooks-global-state";
import DomainBoxFake from "../components/domainBoxFake";
import InfoOneWord from "../components/infoOneWord";
import InfoAvailability from "../components/infoAvailability";

const extensions = [
    {id: 1, name: '.com', unavailable: false},
    {id: 2, name: '.io', unavailable: false},
    {id: 3, name: '.net', unavailable: false},
    {id: 4, name: '.co', unavailable: false},
    {id: 5, name: '.xyz', unavailable: false}
]

function IndexPage() {

    const listLocalStorage = typeof window !== 'undefined' ? localStorage.getItem("list") : "[]";
    let initial = JSON.parse(listLocalStorage);
    if(!initial || !Array.isArray(initial)) {
        initial = [];
    }
    const initialState = { favorites: initial };
    const { useGlobalState } = createGlobalState(initialState);
    const [favorites, setFavorites] = useGlobalState('favorites');

    const [extension, setExtension] = useState(extensions[0])
    const [keyword, setKeyword] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [counter, setCounter] = useState(1)
    const [results, setResults] = useState([])
    const [showTaken, setShowTaken] = useState(true)
    const [error, setError] = useState(false)
    const [isSynonymModalActive, setIsSynonymModalActive] = useState(false)
    const [suffix, setSuffix] = useState(true)

    const onChange = (data) => {
        setIsSynonymModalActive(data)
    }

    const createPost = (random= false) => {
        if (!onlyLetters(keyword)) {
            setError(true)
        } else {
            setIsLoading(true)
            setError(false)
            setCounter(1)
            setResults([])
            axios.get(`.netlify/functions/hello-world?generator=4&domain=` + keyword).then(res => {
                setResults(res.data)
                setIsLoading(false)
            })
        }
    }

    const feelingLucky = (random) => {
        setIsLoading(true)
        setError(false)
        setCounter(1)
        setResults([])
        axios.get(`.netlify/functions/hello-world?generator=4`).then(res => {
            setResults(res.data)
            setKeyword(res.data[0].keyword)
            setIsLoading(false)
        })
    }

    const fetchMoreResults = () => {
        if (!onlyLetters(keyword)) {
            setError(true)
        } else {
            setIsLoading(true)
            axios.get(`.netlify/functions/hello-world?keyword=` + keyword + "&counter=" + (counter + 1) + "&suffix=" + suffix + "&extension=" + extension.id).then(res => {
                const joined = results.concat(res.data);
                setResults(joined)
                setIsLoading(false)
            })
        }
    }

    const clear = () => {
        setCounter(1);
        setResults([]);
        setKeyword("");
    }

    const onlyLetters = (str) => {
        return str.match("^[A-Za-z0-9]+$");
    }

    useEffect(() => {
        const listener = event => {
            if (!isSynonymModalActive && event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                createPost();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [keyword, suffix, isSynonymModalActive, counter]);

    return (

        <Layout favorites={favorites} setFavorites={setFavorites}>
            <SEO
                keywords={[`domain`, `name`, `generator`, `tool`]}
                title="Domainprinter: Best Domain Availability Search - Instant & Fast"
            />

            <section>
                <div className="sm:px-6 px-5 pb-16 dark:bg-gray-800 lg:max-w-4xl mx-auto">
                    <div className="sm:pl-4 container w-full mx-auto pb-16">
                        <div>
                            <h1 className="text-2xl text-5xl lg:text-6xl leading-none font-extrabold tracking-tight text-gray-900 dark:text-white mb-8 sm:mt-10 sm:mb-10">
                                Availability Domain Search</h1>
                        </div>
                        <p className="text-xl text-gray-600 dark:text-white">Check if a domain is available</p>
                        {false && <Synonym onChange={(e) => {
                            onChange(e)
                        }}/>}
                        <div className="container flex pr-6">
                            <div className="mt-4 mb-5 relative w-full lg:mr-52">
                                <div className="absolute top-4"><i
                                    className="fa fa-search text-gray-400 z-20 hover:text-gray-500"></i></div>
                                <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)}
                                       className="h-14 w-full pl-5 pr-40 rounded-lg z-0 focus:shadow focus:outline-none dark:text-gray-800"
                                       placeholder="Enter word..."/>
                                <div>
                                    {error && <p className="pt-2 text-red-900 dark:text-white">
                                        Please type in a single word containing only letters or numbers
                                    </p>}
                                </div>
                                <div className="absolute top-2 right-2">
                                    <button onClick={createPost}
                                            className="h-10 w-20 text-white rounded-lg bg-black hover:bg-gray-800">
                                        {isLoading ? <ReactLoading width="20px" style={{
                                            margin: "0 auto",
                                            fill: "white",
                                            width: "20px",
                                            height: "20px"
                                        }} type={"spin"} color="#fff"/> : "Search"}
                                    </button>
                                </div>
                                {results && results.length > 0 && <div onClick={clear} className="absolute top-4 right-44 mr-4 cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24" stroke="gray">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                </div>}
                                <div className="absolute top-1 right-20 mr-4">
                                    <Listbox value={extension} onChange={setExtension}>
                                        <div className="relative mt-1">
                                            <Listbox.Button
                                                className="relative h-10 w-full py-2 pl-3 pr-10 text-left bg-gray-200 text-black rounded-lg cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                                                <span className="block truncate">{extension.name}</span>
                                                <span
                                                    className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                  <SelectorIcon
                                                      className="w-5 h-5 text-gray-400"
                                                      aria-hidden="true"
                                                  />
                                                </span>
                                            </Listbox.Button>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-10"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options
                                                    className="z-10 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {extensions.map((person, personIdx) => (
                                                        <Listbox.Option
                                                            key={personIdx}
                                                            className={({active}) =>
                                                                `${active ? 'bg-gray-200 text-amber-900' : 'text-gray-900'}
                          cursor-default dark:text-black select-none relative py-2 pl-3 pr-4`
                                                            }
                                                            value={person}
                                                        >
                                                            {({extension, active}) => (
                                                                <>
                      <span
                          className={`${
                              extension ? 'font-medium' : 'font-normal'
                          } block truncate`}
                      >
                        {person.name}
                      </span>
                                                                    {extension ? (
                                                                        <span
                                                                            className={`${
                                                                                active ? 'bg-gray-200 text-amber-600' : 'text-amber-600'
                                                                            }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                                                        >

                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </Listbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        results && results.length > 0 &&
                        <div className="pt-2 pb-2 flex justify-start align-middle">
                            <Switch
                                checked={showTaken}
                                onChange={() => {
                                    if(showTaken) {
                                        setShowTaken(false)
                                    } else {
                                        setShowTaken(true)
                                    }
                                }}
                                className={`${
                                    showTaken ? 'bg-blue-600' : 'bg-gray-200'
                                } relative inline-flex items-center h-6 rounded-full w-11`}
                            >
                            <span
                                className={`${
                                    showTaken ? 'translate-x-6' : 'translate-x-1'
                                } inline-block w-4 h-4 transform bg-white dark:bg-gray-900 rounded-full`}
                            />
                            </Switch>
                            <span className="pl-1">Show taken domains</span>
                        </div>

                    }
                    <div className="mx-auto max-w-4xl grid grid-cols-1 md:grid-cols-3 gap-4 mt-1">
                        {results.map((result) => (
                            <DomainBox showTaken={showTaken} favorites={favorites} setFavorites={setFavorites} result={result}/>
                        ))}
                        {false && results && results.length > 0 && results.length % 3 === 2 &&
                        <>
                            <DomainBoxFake/>
                        </>
                        }
                        {false && results && results.length > 0 && results.length % 3 === 1 &&
                        <>
                            <DomainBoxFake/>
                            <DomainBoxFake/>
                        </>
                        }
                        {false && results && results.length > 0 && <DomainBoxFake/>}
                        {false && results && results.length > 0 && <DomainBoxFake/>}
                        {false && results && results.length > 0 && <DomainBoxFake/>}
                    </div>
                    {false && results.length > 0 && <div className="pt-8 w-full flex justify-between">
                        <button
                            height={"50px"}
                            onClick={() => {
                                setCounter(counter + 1)
                                fetchMoreResults();
                            }}
                            className="w-56 mx-auto flex-none bg-gray-900 hover:bg-gray-700 text-white text-lg leading-6 font-semibold py-3 px-6 border border-transparent rounded-xl focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200">
                            {isLoading ? <ReactLoading width="20px" style={{
                                margin: "0 auto",
                                fill: "white",
                                width: "20px",
                                height: "24px"
                            }} type={"spin"} color="#fff"/> : "Generate more"}
                        </button>
                    </div>}
                </div>
                <InfoAvailability/>
            </section>
        </Layout>
    );
}

export default IndexPage;
